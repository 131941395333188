export const FORM_CATEGORY = 'Form';

export const FORM_ACTION_SUBMIT = 'Submit';
export const FORM_ACTION_REDIRECTED = 'Redirected';
export const FORM_ACTION_ACTIVE_STAGE = 'Stage active';
export const FORM_ACTION_APP_REJECTED = 'Application rejected';
export const FORM_ACTION_API_ERROR_RESPONSE = 'Error response (API)';

export const LANDING_PAGE_CAT = 'Landing page';
export const LOAN_CALCULATOR_ACTION = 'Loan calculator submit';

export const NAVIGATION_CATEGORY = 'Navigation';
export const ACTION_CLICK_TO_FORM = 'Click to form';

export const VALUE_PROP_CATEGORY = 'Value proposition';

export const FORM_ACTION_API_RESPONSE = 'Api response';

export const OUTBOUND_CLICK_CATEGORY = 'Outbound click';
export const DECLINE_PRODUCT_ACTION = 'Decline product';
