import React from "react";
import ReactGA from "react-ga";
import * as ev from "../constants/analyticsEvents";
import { Link, StaticQuery, graphql } from "gatsby";
import { Container, Collapse, Navbar, Nav, NavItem } from "reactstrap";
import { APPLY_CTA } from "../constants/cta";
import Img from "gatsby-image";
import Logo from "./logo";

const brandClasses = "nav-link navbar-brand";

const Navigation = ({ navdata, showFullNav }) => (
  <StaticQuery
    query={graphql`
      query NavQuery {
        allContentfulPage(filter: { node_locale: { regex: "/en-US/" } }) {
          edges {
            node {
              slug
              navigationTitle
              addToMainNavigation
            }
          }
        }
        navImage: file(relativePath: { eq: "uj.png" }) {
          childImageSharp {
            fluid(maxWidth: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => {
      let navigationItems = [];

      data.allContentfulPage.edges.forEach(({ node }) => {
        if (node.addToMainNavigation) {
          navigationItems.push(
            <NavItem key={node.slug}>
              <Link className="nav-link" to={node.slug}>
                {node.navigationTitle}
              </Link>
            </NavItem>
          );
        }
      });

      return (
        <header>
          <Navbar expand="md">
            <Container>
              <Link className={brandClasses} to="/">
                <Logo /> The Money Store
              </Link>
              <Img className="uj" fluid={data.navImage.childImageSharp.fluid} />
              {showFullNav && (
                <React.Fragment>
                  <Nav className="ml-auto align-items-center" navbar>
                    <Collapse isOpen={false} navbar>
                      {navigationItems}
                    </Collapse>
                    <NavItem>
                      <a
                        className="btn btn-primary btn-lg"
                        onClick={() => {
                          ReactGA.event({
                            category: ev.NAVIGATION_CATEGORY,
                            action: ev.ACTION_CLICK_TO_FORM,
                            label: "Click to form",
                          });
                        }}
                        href="/apply"
                      >
                        {APPLY_CTA}
                      </a>
                    </NavItem>
                  </Nav>
                </React.Fragment>
              )}
            </Container>
          </Navbar>
        </header>
      );
    }}
  />
);

export default Navigation;
