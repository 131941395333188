import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { COOKIE_WARN } from '../constants/content';
import { COOKIE_ACCEPT } from '../constants/cta';

const Cookie = () => (
  <CookieConsent
    location="bottom"
    buttonText={COOKIE_ACCEPT}
    cookieName="acceptCookies"
    style={{ background: '#182f2f' }}
    buttonStyle={{
      backgroundColor: '#00D8D3',
      color: '#182f2f',
      fontSize: '14px',
    }}
    expires={150}
  >
    {COOKIE_WARN} <a href="/privacy-policy">More info</a>
  </CookieConsent>
);

export default Cookie;
