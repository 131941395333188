import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const ReviewBar = () => (
  <Container>
    <Row>
      <Col>
        <div id="text-banner-widget" className="reviewsWidget"/>
      </Col>
    </Row>
  </Container>
);

export default ReviewBar;
